<template>
     <div class="invite">
          <el-dialog v-model="dialogVisible" :lock-scroll="false" width="880px" @open="whenOpen"
               @before-close="beforeClose">
               <div class="invite_con">
                    <div class="title">添加受邀投标方</div>
                    <div class="search_wrap flex_row_start_center">
                         <div class="flex_row_start_center">
                              <span class="info1">企业名称：</span>
                              <div class="wrap3 flex-col">
                                   <input type="text" v-model="company" placeholder="请输入企业名称">
                              </div>
                         </div>
                         <div class="wrap7 flex-col" @click="confirm">
                              <span class="info3">搜索</span>
                         </div>
                         <div class="wrap8 flex-col" @click="reset">
                              <span class="info4">重置</span>
                         </div>
                    </div>

                    <div class="table">
                         <el-table :data="enList" style="width: 100%" @selection-change="handleSelectionChange"
                              table-layout="fixed" height="420" ref="elTable" :row-class-name="rowClassMame">
                              <el-table-column type="selection" width="160" label="全选" />
                              <el-table-column prop="companyName" label="企业名称" width="329" align="center">
                                   <template #default="scope">
                                        {{scope.row.enterpriseName}}({{scope.row.companyName}})
                                   </template>
                              </el-table-column>
                              <el-table-column prop="contactPhone" label="联系方式" width="329" align="center" />
                         </el-table>
                    </div>
                    <!-- 分页 -->
                    <div class="flex_row_center_center sld_pagination">
                         <el-pagination @current-change="handleCurrentChange" v-model:currentPage="page.current"
                              :page-size="page.pageSize" layout="prev, pager, next, jumper" :total="page.total"
                              :hide-on-single-page="true">
                         </el-pagination>
                    </div>


               </div>
               <div class="box11 flex_row_center_center">
                    <div class="box12f flex-col" @click="dialogVisible = false">
                         <span class="txt17">取消</span>
                    </div>
                    <div class="box12g flex-col" @click="yes">
                         <span class="txt17">确定</span>
                    </div>
               </div>
          </el-dialog>
     </div>
</template>

<script>
     import { ref, reactive, getCurrentInstance, computed, onMounted, watch, nextTick } from 'vue'
     import { ElMessage } from 'element-plus'
     import { useStore } from 'vuex'
     export default {
          props: {
               visi: Boolean
          },
          setup(props, { emit }) {
               const { proxy } = getCurrentInstance()
               const dialogVisible = ref(false)
               const tmpList = ref([])
               const enList = ref([])
               const company = ref('')
               const store = useStore()
               const memberInfo = store.state.memberInfo
               const chosenList = ref([])
               const current = ref(1)
               const page = {}
               const isPage = ref(false)
               const afterChange = ref(false)
               const getEnlist = () => {
                    let params = {}
                    if (!company.value.trim()) {
                         delete params.enterpriseName
                    } else {
                         params.enterpriseName = company.value
                    }

                    params.current = current.value
                    proxy.$get('v3/business/front/bid/enterpriseList', params).then(res => {
                         if (res.state == 200) {
                              enList.value = res.data.list.filter(i => i.enterpriseId != memberInfo.enterpriseId)
                              nextTick(() => {
                                   if (tmpList.value[current.value] && tmpList.value[current.value].length) {
                                        tmpList.value[current.value].forEach(row => {
                                             let index = enList.value.findIndex(i => i.enterpriseId == row.enterpriseId)
                                             if (index > -1) {
                                                  proxy.$refs.elTable.toggleRowSelection(enList.value[index], true)
                                             }
                                        });
                                   }
                              })

                              Object.assign(page, res.data.pagination)
                         }
                    })
               }


               const confirm = () => {
                    getEnlist()
               }

               const reset = () => {
                    company.value = ''
                    getEnlist()
               }


               const handleSelectionChange = (select) => {
                    if (isPage.value) {
                         isPage.value = false
                    } else {
                         tmpList.value[current.value] = select
                    }
               }

               const handleCurrentChange = (e) => {
                    isPage.value = true
                    current.value = e
                    getEnlist()
               }

               onMounted(() => {
                    getEnlist()
               })



               const yes = () => {
                    if (!tmpList.value.flat(1).length) {
                         ElMessage.warning('请选择投标方')
                         return
                    }

                    chosenList.value = tmpList.value.flat(1)
                    emit('chosenList', chosenList.value)
               }


               const whenOpen = () => {

               }


               const beforeClose = (done) => {
                    done()
               }


               const deleteOnTable = (item) => {
                    let index = chosenList.value.findIndex(i => i.enterpriseId == item.enterpriseId)
                    if (index > -1) {
                         chosenList.value.splice(index, 1)
                    }

                    let index2 = tmpList.value[current.value].findIndex(i => i.enterpriseId == item.enterpriseId)

                    if (index2 > -1) {
                         proxy.$refs.elTable.toggleRowSelection(tmpList.value[current.value][index], false)
                         // tmpList.value[current.value].splice(index, 1)


                    }

               }

               const rowClassMame = ({ row, index }) => {
                    if (dialogVisible.value) {
                         let { enterpriseId } = row
                         let fdx = chosenList.value.findIndex(i => i.enterpriseId == enterpriseId)
                         if (fdx > -1) {
                              return 'row_dis_none'
                         } else {
                              return 'row_dis_block'
                         }

                    } else {
                         return 'row_dis_none'
                    }



               }

               return {
                    dialogVisible,
                    handleSelectionChange,
                    enList,
                    company,
                    confirm,
                    reset,
                    yes,
                    page,
                    handleCurrentChange,
                    whenOpen,
                    beforeClose,
                    deleteOnTable,
                    rowClassMame
               }
          }
     }
</script>


<style lang="scss">
     .el-dialog .el-dialog__header {
          display: none;
     }

     .el-dialog__body {
          padding: 0;
     }

     .invite_con {
          padding: 30px;

          .title {
               font-size: 22px;
               font-family: Microsoft YaHei;
               font-weight: bold;
               color: #000000;
               text-align: center;
          }

          .search_wrap {
               margin-top: 46px;

               .info1 {
                    white-space: nowrap;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
               }

               .wrap3 {
                    width: 257px;
                    height: 34px;
                    background: #FFFFFF;
                    border: 1px solid #BFBFBF;
                    border-radius: 5px;
                    padding: 0 10px;
               }

               .word3 {
                    white-space: nowrap;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    margin: 0px 0 0 22px;
               }



               .wrap7 {
                    background-color: rgba(40, 95, 222, 1);
                    border-radius: 4px;
                    height: 32px;
                    width: 68px;
                    margin: 0px 0 0 31px;
                    cursor: pointer;

                    .info3 {
                         width: 28px;
                         height: 13px;

                         color: rgba(255, 255, 255, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                         display: block;
                         margin: 9px 0 0 19px;
                    }
               }

               .wrap8 {
                    border-radius: 4px;
                    height: 32px;
                    border: 1px solid rgba(40, 95, 222, 1);
                    width: 68px;
                    margin: 0px 0 0 20px;
                    cursor: pointer;

                    .info4 {
                         width: 29px;
                         height: 13px;

                         color: rgba(40, 95, 222, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                         display: block;
                         margin: 9px 0 0 19px;
                    }
               }
          }

          .table {
               margin-top: 20px;
               border: 1px solid #E8E8E8;
               border-bottom: none;

               .has-gutter tr th {
                    background: #F2F2F2;
               }
          }

          .row_dis_none {
               display: none;
          }

          .row_dis_block {
               display: 'block';
          }

     }

     .box11 {
          box-shadow: 0px 2px 10px 0px rgba(131, 131, 131, 0.3);
          background-color: rgba(255, 255, 255, 1);
          height: 79px;
          /* margin-top: 38px; */

          .box12g {
               width: 120px;
               height: 40px;
               background: #285FDE;
               border-radius: 3px;
               cursor: pointer;
               text-align: center;
               line-height: 40px;

               .txt17 {
                    color: rgba(255, 255, 255, 1);
                    font-size: 16px;
                    white-space: nowrap;
                    display: block;
               }
          }


          .box12f {
               width: 120px;
               height: 40px;
               background: #FFFFFF;
               border: 1px solid #BFBFBF;
               border-radius: 3px;
               cursor: pointer;
               text-align: center;
               line-height: 40px;
               margin-right: 50px;

               .txt17 {

                    color: #333333;
                    font-size: 16px;
                    white-space: nowrap;
                    display: block;
               }
          }
     }
</style>