<template>
     <div class="open_bid_con">
          <div class="open_bid">

               <div class="bid_con">


                    <div class="open_title">发布招标公告</div>

                    <div class="outer2 flex-row">
                         <div class="bd3 flex_row_start_center">
                              <div class="flex_row_end_center bd31">
                                   <span class="word2">*</span>
                                   <span class="info2">招标方式：</span>
                              </div>

                              <div class="flex_row_start_start">
                                   <div class="op_item" :class="{chosen:opType==1}" @click="choseType(1)">公开招标</div>
                                   <div class="op_item" :class="{chosen:opType==2}" @click="choseType(2)">邀请招标</div>
                              </div>

                         </div>
                    </div>

                    <div v-if="opType==2">
                         <div class="outer2 flex-row">
                              <div class="bd3 flex_row_start_center">
                                   <div class="flex_row_end_center bd31">
                                        <span class="word2">*</span>
                                        <span class="info2">受邀投标方：</span>
                                   </div>
                                   <div class="outer15 flex-col" @click="$refs.invite.dialogVisible=true">
                                        <span class="info10">+&nbsp;添加投标方</span>
                                   </div>
                              </div>
                         </div>

                         <div class="outer2 flex-row">
                              <div class="bd3 flex_row_start_center">
                                   <div class="flex_row_end_center bd31">
                                   </div>
                                   <div class="table_bd">
                                        <el-table :data="inviteList" style="width: 100%">
                                             <el-table-column type="index" width="120" align="center" label="序号" />
                                             <el-table-column prop="companyName" label="企业名称" width="349"
                                                  align="center">
                                                  <template #default="scope">
                                                       {{scope.row.enterpriseName}}({{scope.row.companyName}})
                                                  </template>
                                             </el-table-column>
                                             <el-table-column prop="contactPhone" label="联系方式" width="349"
                                                  align="center" />
                                             <el-table-column label="操作" width="210" align="center">
                                                  <template #default="scope">
                                                       <span class="del_enterPrise" @click="delInvite(scope)">删除</span>
                                                  </template>
                                             </el-table-column>
                                        </el-table>
                                   </div>
                              </div>
                         </div>
                    </div>


                    <div class="outer2 flex-row" v-for="(item,index) in input_list1" :key="index">
                         <div class="bd3 flex_row_start_center">
                              <div class="flex_row_end_center bd31">
                                   <span class="word2">*</span>
                                   <span class="info2">{{item.title}}：</span>
                              </div>

                              <div :class="{validate:validate[item.name]}">
                                   <div class="bd4_1 flex_row_start_center vBorder">
                                        <input type="text" v-model="item.value" maxlength="100"
                                             @input="inputEvent(item.name)">
                                        <span class="word3">{{item.value.length}}/100</span>
                                   </div>
                                   <span class="error_msg">{{validate[item.name]}}</span>
                              </div>
                         </div>
                    </div>

                    <div class="outer2 flex-row">
                         <div class="bd3 flex_row_start_center">
                              <div class="flex_row_end_center bd31">
                                   <span class="word2">*</span>
                                   <span class="info2">招标类型：</span>
                              </div>

                              <div class="box2g flex_row_start_center" @click="checkRadio(1)">
                                   <img src="@/assets/inquiry/inq_icon5.png" v-if="bidType==1" />
                                   <div class="box3 flex-col" v-else></div>
                                   <span class="info11">货物招标</span>
                              </div>

                              <div class="box2g flex_row_start_center" @click="checkRadio(2)">
                                   <img src="@/assets/inquiry/inq_icon5.png" v-if="bidType==2" />
                                   <div class="box3 flex-col" v-else></div>
                                   <span class="info11">工程招标</span>
                              </div>

                              <div class="box2g flex_row_start_center" @click="checkRadio(3)">
                                   <img src="@/assets/inquiry/inq_icon5.png" v-if="bidType==3" />
                                   <div class="box3 flex-col" v-else></div>
                                   <span class="info11">服务招标</span>
                              </div>
                         </div>
                    </div>

                    <div class="outer2 flex-row" v-for="(item,index) in input_list2" :key="index">
                         <div class="bd3 flex_row_start_center">
                              <div class="flex_row_end_center bd31">
                                   <span class="word2">*</span>
                                   <span class="info2">{{item.title}}：</span>
                              </div>

                              <div :class="{validate:validate[item.name]}">
                                   <div class="bd4_1 flex_row_start_center vBorder">
                                        <input type="text" v-model="item.value" maxlength="50"
                                             @input="inputEvent(item.name)">
                                        <span class="word3">{{item.value.length}}/50</span>
                                   </div>
                                   <span class="error_msg">{{validate[item.name]}}</span>
                              </div>
                         </div>
                    </div>

                    <div class="outer2 flex-row">
                         <div class="bd3 flex_row_start_center">
                              <div class="flex_row_end_center bd31">
                                   <span class="word2">*</span>
                                   <span class="info2">预算金额：</span>
                              </div>

                              <div :class="{validate:validate.budget}">
                                   <div class="bd4_1 w320 flex_row_start_center vBorder">
                                        <input type="number" v-model="budget" maxlength="5"
                                             @input="inputEvent('budget',$event)">
                                   </div>
                                   <span class="error_msg">{{validate.budget}}</span>
                              </div>

                              <div class="info2" style="margin-left:10px">元</div>
                         </div>
                    </div>

                    <div class="outer2 flex-row">
                         <div class="bd3 flex_row_start_center">
                              <div class="flex_row_end_center bd31">
                                   <span class="word2">*</span>
                                   <span class="info2">项目实施地区：</span>
                              </div>
                              <div :class="{validate:validate.deplArea}">
                                   <div class="section9 vBorder">
                                        <el-cascader ref="cascaderAddr" v-model="deplArea" :options="areaData"
                                             placeholder="请选择所在地区" :props="cascaderProps2" size="small" clearable
                                             @change="inputEvent('deplArea',$event)">
                                        </el-cascader>
                                   </div>
                                   <span class="error_msg">{{validate.deplArea}}</span>
                              </div>
                         </div>
                    </div>


                    <div class="outer2 flex-row">
                         <div class="bd3 flex_row_start_start">
                              <div class="flex_row_end_center bd31 top">
                                   <span class="word2">*</span>
                                   <span class="info2">投标截止时间：</span>
                              </div>
                              <div class="section2g">
                                   <div :class="{validate:validate.deadline}">
                                        <div class="flex-col vBorder section21g">
                                             <el-date-picker v-model="deadline" type="datetime" placeholder="请选择"
                                                  :shortcuts="shortcuts" @change="inputEvent('deadline')"
                                                  :disabled-date="disabledDate" value-format="YYYY-MM-DD h:m">
                                             </el-date-picker>
                                        </div>
                                        <span class="error_msg">{{validate.deadline}}</span>
                                   </div>
                              </div>


                              <div class="flex_row_end_center bd31 top">
                                   <span class="word2">*</span>
                                   <span class="info2">开标时间：</span>
                              </div>
                              <div class="section2g flex-col">
                                   <div :class="{validate:validate.openTime}">
                                        <div class="flex-col vBorder section21g">
                                             <el-date-picker v-model="openTime" type="datetime" placeholder="请选择"
                                                  :shortcuts="shortcuts" @change="inputEvent('openTime')"
                                                  :disabled-date="disabledDate" value-format="YYYY-MM-DD h:m">
                                             </el-date-picker>
                                        </div>
                                        <span class="error_msg">{{validate.openTime}}</span>
                                   </div>
                              </div>


                              <div class="set_bid_end flex_row_start_center top">
                                   <el-checkbox v-model="setDeadLine" id="label" @change="changeBox">设置为投标截止时间
                                   </el-checkbox>

                                   <span class="error_msg" style="margin-left:10px">{{validate.previous}}</span>

                              </div>
                         </div>
                    </div>

                    <div style="margin-top: 20px;" class="flex_row_start_start outer2">
                         <div class="bd3">
                              <div class="flex_row_end_center bd31">
                                   <span class="word2">*</span>
                                   <span class="info2">公告内容：</span>
                              </div>
                         </div>

                         <div>
                              <vue-ueditor-wrap v-model="content" :config="editorConfig" editor-id="editor-demo-01"
                                   @input="inputEvent('content')">
                              </vue-ueditor-wrap>
                              <span class="ude_error">{{validate.content}}</span>
                         </div>
                    </div>

                    <div class="outer14_1">
                         <div class="outer14 flex-row">
                              <span class="txt3">附件：</span>
                              <div class="flex-row" :class="{disabledUpload:fileList.length>=5}">
                                   <label for="upload">
                                        <div class="outer15 flex-col">
                                             <span class="info10">+&nbsp;添加附件</span>
                                        </div>
                                   </label>
                                   <input type="file" name="" id="upload" size="1"
                                        accept="image/png,image/jpeg,image/jpg,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.doc,.docx,.xls,.xlsx,.pdf"
                                        @change="changeFile" multiple ref="inputTab">
                                   <span class="word15">最多可添加5个文件。支持添加Word、PDF、Excel等文件格式。</span>
                              </div>
                         </div>
                         <div class="outer16 flex-row" v-for="(file,fileIdx) in fileList" :key="fileIdx">
                              <img class="label5" src="@/assets/inquiry/inq_icon4.png" v-if="file.type=='img'" />

                              <img class="label5" src="@/assets/inquiry/excel.png"
                                   v-else-if="file.type=='xlsx'||file.type=='xls'" />

                              <img class="label5" src="@/assets/inquiry/inq_icon10.png" v-else>
                              <span
                                   class="word16">{{file.type=='img'?`图片${fileIdx+1}.${file.suffix}`:`文档${fileIdx+1}.${file.suffix}`}}</span>

                              <span class="txt4" @click="clearFileList(fileIdx)">删除</span>
                         </div>
                    </div>


               </div>


               <div class="box11 flex_row_center_center">

                    <div class="box12g flex-col" @click="initiate_bid">
                         <span class="txt17">确认发布</span>
                    </div>
               </div>

               <invite :visi="invite_visible" @chosenList="chosenList" ref="invite"></invite>
          </div>
     </div>
</template>

<script>
     import editorConfig from './editorConfig'
     import { ref, reactive, getCurrentInstance, onMounted, toRefs, computed, watch } from 'vue'
     import areaData from "@/assets/bid_area.json";
     import { inqUtils } from '@/utils/inquiry_utils.js'
     import invite from './component/invite'
     import { ElMessage } from 'element-plus'
     import { useRouter } from 'vue-router'
     import { useStore } from 'vuex'
     export default {
          components: {
               invite
          },
          setup() {
               const router = useRouter()
               const { proxy } = getCurrentInstance()
               const content = ref('')

               const opType = ref(1)
               const { areaDataArray, cascaderProps2, disabledDate } = inqUtils();
               const { uploadFile, fileList, clearFileList, formatTime, setFileDTO } = inqUtils()

               const validate = reactive({
                    receiverName: '',
               })
               const invite_visible = ref(false)
               const bidType = ref(1)
               const setDeadLine = ref(false)
               const input_list1 = ref([
                    {
                         title: '招标主题',
                         name: 'title',
                         value: '',
                    },
                    {
                         title: '招标编号',
                         name: 'code',
                         value: '',
                    }
               ])

               const inviteList = ref([])

               const input_list2 = ref([
                    {
                         title: '招标单位',
                         name: 'uintName',
                         value: '',
                    },
                    {
                         title: '单位所在地址',
                         name: 'uintAddress',
                         value: '',
                    },
                    {
                         title: '联系方式',
                         name: 'contact',
                         value: '',
                    }
               ])


               const input_list3 = reactive({
                    budget: '',
                    deplArea: '',
                    projectAddressAll: '',
                    projectCityCode: '',
                    projectProvinceCode: '',
                    openTime: '',
                    deadline: ''
               })


               const choseType = (type) => {
                    opType.value = type
               }


               watch(() => input_list3.budget, () => {
                    input_list3.budget = input_list3.budget.slice(0, 9)
               })

               const inputEvent = (val, e) => {
                    validate[val] = ''
                    if (val == 'deadline') {
                         if (setDeadLine.value) input_list3.openTime = input_list3.deadline
                         if (input_list3.openTime && (new Date(input_list3.openTime.getTime()) < new Date(input_list3.deadline).getTime())) {
                              ElMessage.warning('开标时间需大于投标截止时间')
                              input_list3.deadline = ''
                         }
                    }

                    if (val == 'openTime') {
                         if (input_list3.deadline && (new Date(input_list3.openTime).getTime() < new Date(input_list3.deadline).getTime())) {
                              ElMessage.warning('开标时间需大于投标截止时间')
                              input_list3.openTime = ''
                         }
                    }

                    if (val == 'deplArea') {
                         const checkedNode = proxy.$refs["cascaderAddr"].getCheckedNodes();
                         input_list3.projectAddressAll = checkedNode[0].pathLabels.join('/')
                         input_list3.projectProvinceCode = input_list3.deplArea[0]
                         input_list3.projectCityCode = input_list3.deplArea[1]
                    }

                    if (val == 'budget') {
                         console.log(e.target.value, 'e.target.value')
                         input_list3.budget = e.target.value
                         let low = String(e.target.value).split('.')
                         if (low[1] && low[1].length >= 2) input_list3.budget = Number(e.target.value).toFixed(2)
                    }
               }


               watch(content, () => {
                    if (content.value) {
                         validate.content = ''
                    }
               })


               const changeBox = () => {


                    if (input_list3.deadline) {
                         validate.previous = ''
                         if (setDeadLine.value) {
                              input_list3.openTime = input_list3.deadline
                         }
                    } else {
                         validate.previous = '请优先设置投标截止时间'
                         setDeadLine.value = false
                    }
               }


               const checkInputEvent = () => {
                    let { budget, deplArea, openTime, deadline } = input_list3


                    for (let i = 0; i < input_list1.value.length; i++) {
                         let { title, name, value } = input_list1.value[i]
                         if (!value) {
                              validate[name] = `请填写${title}`
                              return
                         }
                    }

                    for (let i = 0; i < input_list2.value.length; i++) {
                         let { title, name, value } = input_list2.value[i]
                         if (!value) {
                              validate[name] = `请填写${title}`
                              return
                         }
                    }


                    if (!budget) {
                         validate.budget = '请填写预算金额'
                         return false
                    }

                    if (!deplArea) {
                         validate.deplArea = '请选择项目实施地区'
                         return false
                    }

                    if (!deadline) {
                         validate.deadline = '请选择投标截止时间'
                         return false
                    }

                    if (!openTime) {
                         validate.openTime = '请选择开标时间'
                         return false
                    }

                    if (!content.value) {
                         validate.content = '请填写公告内容'
                         return
                    }


                    return true
               }


               const changeFile = (e) => {
                    if (fileList.length >= 5) {
                         ElMessage('最多上传5个附件')
                         return
                    }
                    uploadFile(e, () => {
                         proxy.$refs.inputTab.value = ''
                    }, 5)
               }



               const checkRadio = (n) => {
                    bidType.value = n
               }


               const chosenList = (list) => {
                    inviteList.value = JSON.parse(JSON.stringify(list))
                    proxy.$refs.invite.dialogVisible = false
               }


               const delInvite = (scope) => {
                    let delTarget = inviteList.value[scope.$index]
                    inviteList.value.splice(scope.$index, 1)

                    proxy.$refs.invite.deleteOnTable(delTarget)
               }



               const initiate_bid = () => {
                    let param = {}

                    if (opType.value == 2) {
                         if (!inviteList.value.length) {
                              ElMessage.warning('请选择受邀方')
                              return
                         } else {
                              param.enterpriseIds = inviteList.value.map(k => k.enterpriseId).join(',')
                         }

                    }



                    if (!checkInputEvent()) {
                         return
                    }


                    input_list1.value.forEach(item => {
                         param[item.name] = item.value
                    })

                    input_list2.value.forEach(item => {
                         param[item.name] = item.value
                    })


                    Object.keys(input_list3).forEach(item => {
                         if (item == 'openTime' || item == 'deadline') {
                              param[item] = formatTime(input_list3[item])
                         } else {
                              param[item] = input_list3[item]
                         }
                    })
                    delete param.deplArea
                    param.type = bidType.value
                    param.mode = opType.value
                    param.content = content.value
                    if (fileList.value.length) {
                         param.attachment = fileList.value.map(i => i.path).join(',')
                    } else {
                         delete param.attachment
                    }


                    proxy.$post('v3/business/front/bid/publish', param, 'json').then(res => {
                         if (res.state == 200) {
                              ElMessage.success(res.msg)

                              setTimeout(() => {
                                   router.push('/member/bid')
                              }, 1000)
                         } else {
                              ElMessage.error(res.msg)
                         }
                    })
               }

               return {
                    content,
                    editorConfig,
                    validate,
                    opType,
                    input_list1,
                    input_list2,
                    ...toRefs(input_list3),
                    areaDataArray,
                    cascaderProps2,
                    disabledDate,
                    fileList,
                    clearFileList,
                    invite_visible,
                    choseType,
                    inputEvent,
                    initiate_bid,
                    checkRadio,
                    bidType,
                    changeFile,
                    setDeadLine,
                    changeBox,
                    areaData,
                    chosenList,
                    inviteList,
                    delInvite
               }
          }
     }
</script>


<style lang="scss">
     .validate {
          .vBorder {
               border-color: #F30606 !important;
          }

          .error_msg {
               display: block;
               margin-top: 2px;
          }
     }



     .error_msg,
     .ude_error {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #F30606;
     }

     .section9 {
          .el-input__inner {
               border: none;
               height: 27px;
               width: 318px;
          }
     }

     #upload {
          display: none;
     }

     .del_enterPrise {
          cursor: pointer;

          &:hover {
               color: $colorMain;
          }
     }

     .section2g {
          .el-input__inner {
               border: none;
               height: 28px;
               width: 220px;
          }

          .el-input {
               height: 27px;
               line-height: 30px;
          }

          .el-input__icon {
               height: 27px;
               line-height: 30px;
          }
     }

     /*** 消除input元素 type="number" 时默认的 加减按钮*/
     input[type="number"]::-webkit-inner-spin-button,
     input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
     }

     /*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
     input[type="number"] {
          -moz-appearance: textfield;
     }

     input {
          border: none;
          outline: none;
          height: 32px;
     }


     .open_bid_con {
          background: #F5F5F5;
          padding: 1px;

          .open_bid {
               width: 1200px;
               background: #FFFFFF;
               margin: 0 auto;
               margin-top: 20px;

               .bid_con {
                    padding: 20px;

               }

               .open_title {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    text-align: center;
                    margin-top: 21px;
               }

               .outer2 {
                    width: 100%;

                    .bd3 {
                         margin-top: 20px;

                         .bd31 {
                              /* margin-top: 10px; */
                              margin-right: 12px;
                              width: 113px;
                              text-align: right;

                         }

                         .top {
                              margin-top: 8px;
                         }

                         .word2 {
                              color: rgba(240, 2, 2, 1);
                              font-size: 14px;
                              line-height: 14px;
                              margin-right: 4px;
                         }

                         .info2 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              line-height: 14px;
                              white-space: nowrap;
                         }

                         .outer15 {
                              border-radius: 4px;
                              height: 35px;
                              border: 1px dashed rgba(40, 95, 222, 1);
                              width: 150px;
                              cursor: pointer;

                              .info10 {
                                   height: 14px;
                                   color: rgba(40, 95, 222, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                                   display: block;
                                   line-height: 35px;
                                   text-align: center;
                              }
                         }
                    }

                    .bd4_1 {
                         background: #F7F8FA;
                         height: 36px;
                         border: 1px solid rgba(201, 201, 201, 1);
                         padding: 0 10px;
                         border-radius: 3px;
                         width: 1030px;

                         &.w320 {
                              width: 320px !important;
                         }

                         input {
                              flex: 1;
                              background: #F7F8FA;

                         }

                         .word3 {
                              color: rgba(153, 153, 153, 1);
                              font-size: 12px;
                         }
                    }


                    .section9 {
                         width: 320px;
                         height: 32px;
                         background: #F7F8FA;
                         border: 1px solid #C9C9C9;
                         border-radius: 3px;

                         input {
                              background: #F7F8FA;
                         }
                    }


                    .section2g {
                         background-color: rgba(255, 255, 255, 1);

                         .section21g {
                              border: 1px solid #C9C9C9;
                              border-radius: 3px;

                              height: 32px;
                         }
                    }


                    .set_bid_end {
                         margin-left: 20px;
                    }



                    .op_item {
                         margin-left: 10px;
                         width: 100px;
                         height: 36px;
                         opacity: 1;
                         border-radius: 18px;
                         text-align: center;
                         line-height: 36px;
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         color: #333333;
                         cursor: pointer;

                         &.chosen {

                              background: #6590F3;
                              color: #FFFFFF;

                         }
                    }
               }


               .box2g {
                    margin-left: 23px;
                    cursor: pointer;

                    img {
                         width: 18px;
                         height: 18px;
                    }

                    .box3 {
                         background-color: rgba(255, 255, 255, 1);
                         border-radius: 50%;
                         width: 18px;
                         height: 18px;
                         border: 2px solid rgba(201, 201, 201, 1);
                    }

                    .info11 {

                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;

                         margin: 0px 0 0 8px;
                    }
               }

               .outer14 {
                    width: 618px;
                    height: 35px;
                    margin: 30px 0 0 79px;

                    .txt3 {
                         height: 15px;
                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 15px;
                         margin-top: 10px;
                         display: block;
                         margin-right: 4px
                    }

                    .disabledUpload {
                         position: relative;

                         &:after {
                              content: '';
                              position: absolute;
                              top: 0;
                              left: 0;
                              right: 0;
                              bottom: 0;
                              opacity: 0.3;
                         }



                         .outer15 {
                              border: 1px dashed #999;
                              color: #999;

                              .info10 {
                                   color: #999;
                              }
                         }
                    }

                    .outer15 {
                         border-radius: 4px;
                         height: 35px;
                         border: 1px dashed rgba(40, 95, 222, 1);
                         width: 150px;
                         cursor: pointer;

                         .info10 {
                              width: 70px;
                              height: 14px;
                              color: rgba(40, 95, 222, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              display: block;
                              margin: 10px 0 0 40px;
                         }
                    }

                    .word15 {
                         height: 14px;
                         color: rgba(153, 153, 153, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                         margin-top: 11px;
                         display: block;
                         margin-left: 30px;
                    }
               }

               .outer16 {
                    width: 165px;
                    height: 31px;
                    margin: 21px 0 0 115px;

                    .label5 {
                         width: 31px;
                         height: 31px;
                    }

                    .word16 {
                         width: 71px;
                         height: 14px;
                         color: rgba(51, 51, 51, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                         display: block;
                         margin: 6px 0 0 14px;
                    }

                    .txt4 {
                         width: 25px;
                         height: 12px;
                         color: rgba(40, 95, 222, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 12px;
                         display: block;
                         margin: 7px 0 0 24px;
                         cursor: pointer;

                         &:hover {
                              text-decoration: underline;
                         }
                    }
               }

               .table_bd {
                    border: 1px solid #E7E7E7;
                    border-bottom: none;

                    .has-gutter tr th {
                         background: #F2F2F2;
                    }
               }

               .box11 {
                    box-shadow: 0px 2px 10px 0px rgba(131, 131, 131, 0.3);
                    background-color: rgba(255, 255, 255, 1);
                    height: 79px;
                    margin-top: 18px;

                    .box12g {
                         width: 120px;
                         height: 40px;
                         background: #285FDE;
                         border-radius: 3px;
                         cursor: pointer;
                         text-align: center;
                         line-height: 40px;

                         .txt17 {
                              color: rgba(255, 255, 255, 1);
                              font-size: 16px;
                              white-space: nowrap;
                              display: block;
                         }
                    }


                    .box12f {
                         width: 120px;
                         height: 40px;
                         background: #FFFFFF;
                         border: 1px solid #BFBFBF;
                         border-radius: 3px;
                         cursor: pointer;
                         text-align: center;
                         line-height: 40px;
                         margin-right: 50px;

                         .txt17 {

                              color: #333333;
                              font-size: 16px;
                              white-space: nowrap;
                              display: block;
                         }
                    }
               }
          }
     }
</style>